<template>
    <div class="inner-container">
        <div class="section-title program"><span>Гид</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>Инструкции</span></div>
        <div class="filter-container" v-if="user.roles && user.roles.includes('gid_admin')">
            <div class="filter-actions">
                <b-button variant="primary"
                          @click="modalOpen = true;">
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.4996 19.136C15.2694 19.136 19.136 15.2694 19.136 10.4996C19.136 5.72991 15.2694 1.86328 10.4996 1.86328C5.72991 1.86328 1.86328 5.72991 1.86328 10.4996C1.86328 15.2694 5.72991 19.136 10.4996 19.136Z"
                            stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10.5 6.8359V14.1641" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M6.83594 10.5H14.1641" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                    <span>Добавить</span>
                </b-button>
            </div>
        </div>
        <div class="table-container">
            <div class="b-table-sticky-header table-responsive-true">
                <table
                    class="table b-table table-bordered b-table-no-border-collapse constructor-table"
                >
                    <thead>
                        <tr class="text-center">
                            <th style="width: 50px;">
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                >
                                    <img
                                        v-if="openCount > 0"
                                        src="../monitoring-snp/icons/open.svg"
                                        alt=""
                                        @click="handleCloseAll()"
                                    >
                                    <img
                                        v-else
                                        src="../monitoring-snp/icons/closed.svg"
                                        alt=""
                                        @click="handleOpenAll()"
                                    >
                                </button>
                            </th>
                            <th style="width: 22%; max-width: 22%;" rowspan="2">Режим</th>
                            <th style="width: auto;" rowspan="2">Описание</th>
                            <th style="width: 22%; max-width: 22%;" rowspan="2">Текстовая инструкция</th>
                            <th style="width: 22%; max-width: 22%;" rowspan="2">Видео инструкция</th>
                            <th style="width: 50px;"/>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="item of globalArr">
                            <tr
                                :key="item.id"
                                :class="item.status === 'ACTIVE'
                                    ? (user.roles && user.roles.includes('gid_admin'))
                                        ? `tr-level--1`
                                        : `tr-level--${item.level}`
                                    : 'tr-level--3'"
                                v-if="((user.roles && user.roles.includes('gid_admin')) || item.status === 'ACTIVE') && item.visible"
                            >
                                <td class="text-center">
                                    <button
                                        type="button"
                                        class="btn btn-secondary"
                                        v-if="item.parent"
                                    >
                                        <img
                                            v-if="item.open"
                                            src="../monitoring-snp/icons/open.svg"
                                            alt=""
                                            @click="handleClose(item)"
                                        >
                                        <img
                                            v-else
                                            src="../monitoring-snp/icons/closed.svg"
                                            alt=""
                                            @click="handleOpen(item)"
                                        >
                                    </button>
                                </td>
                                <td>
                                    <div class="item__title">{{ item.name }}</div>
                                </td>
                                <td>
                                    <p class="item__description">{{ item.description }}</p>
                                </td>
                                <td>
                                    <template v-for="file of item.file">
                                        <div
                                            :key="file.name"
                                            class="item__instruction"
                                            @click="handleGetFile(file)"
                                        >
                                            <img
                                                v-if="['doc', 'docx'].includes(getFileExtension(file.name))"
                                                src="./icons/textInstructionIcon.svg"
                                                alt="Microsoft word icon"
                                            />
                                            <img v-else-if="['xls', 'xlsx'].includes(getFileExtension(file.name))"
                                                 src="./icons/excel.svg"
                                                 alt="Microsoft excel icon"
                                            >
                                            <img v-else-if="['ppt', 'pptx'].includes(getFileExtension(file.name))"
                                                 src="./icons/powerpoint.svg"
                                                 alt="Microsoft powerpoint icon"
                                            >
                                            <img v-else-if="['pdf'].includes(getFileExtension(file.name))"
                                                 src="./icons/pdf_icon.svg"
                                                 alt="PDF icon"
                                            >
                                            {{ file.name }}
                                        </div>
                                    </template>
                                </td>
                                <td>
                                    <a
                                        v-if="item.videoLink"
                                        :href="item.videoLink"
                                        target="_blank"
                                        class="item__instruction"
                                    >
                                        <img src="./icons/videoInstructionIcon.svg" alt="Video icon">
                                        Видеоинструкция
                                    </a>
                                </td>
                                <td>
                                    <div
                                        v-if="user.roles && user.roles.includes('gid_admin')"
                                        @click="handleEdit(item)"
                                        class="text-center"
                                    >
                                        <img
                                            class="more-actions"
                                            src="../monitoring-snp/icons/3-vertical-dots.svg"
                                            alt=""
                                        />
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <tr v-if="user.roles && user.roles.includes('gid_admin')" class="add-row" @click="toggleModal">
                            <td colspan="1">
                                <img src="./icons/addIcon.svg" alt="Add icon"/>
                            </td>
                            <td colspan="5">
                                <span class="add-button">Добавить</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <b-modal
            v-model="modalOpen"
            @hide="handleModalClose"
            size="lg"
            centered
            hide-header
            hide-footer
        >
            <div class="modal__title">Добавить/Редактировать</div>
            <div class="modal__card">
                <template v-for="(options, index) of selectedParentOptions">
                    <div :key="index" class="card__row">
                        <div class="row__label">{{ selectHeaders[index] }}</div>
                        <multiselect
                            v-model="selectedFilters[index]"
                            :options="options || []"
                            class="custom-multiselect"
                            label="nameRu"
                            @input="handleFilterChange(index)"
                        />
                    </div>
                </template>
                <template v-if="selectedModule.id">
                    <div class="card__row">
                        <div class="row__label">Описание</div>
                        <textarea class="row__textarea" v-model="selectedModule.description"/>
                    </div>
                    <div class="card__row">
                        <div class="row__label">Ссылка</div>
                        <input type="text" class="row__input" v-model="selectedModule.videoLink">
                    </div>
                </template>
            </div>
            <template v-if="selectedModule.id">
                <div class="modal__toggle">
                    <b-form-checkbox v-model="selectedModule.checked" switch/>
                    <div>{{ selectedModule.checked ? 'Скрыть' : 'Показать' }}</div>
                </div>
                <template v-for="file of selectedModule.file">
                    <div :key="file.id" class="modal__file">
                        <div class="modal__upload">
                            <img src="./icons/uploadIcon.svg" alt="Upload icon">
                            <div @click="handleGetFile(file)">{{ file.name }}</div>
                        </div>
                        <div @click="handleFileDelete(file)" class="file__delete">
                            <img src="./icons/cross-icon.svg" alt="Cross icon">
                            <span>Удалить</span>
                        </div>
                    </div>
                </template>
                <div v-if="!selectedModule.file.length" class="modal__file">
                    <div class="modal__upload">
                        <img src="./icons/uploadIcon.svg" alt="Upload icon">
                        <input id="file-upload" type="file" @change="handleFileUpload">
                    </div>
                </div>
                <div v-if="selectedModule.file.length === 1" class="modal__file">
                    <div class="modal__upload">
                        <img src="./icons/uploadIcon.svg" alt="Upload icon">
                        <input id="file-upload2" type="file" @change="handleFileUpload">
                    </div>
                </div>
            </template>
            <div class="modal__footer">
                <b-btn variant="success" @click="handleSave">Сохранить</b-btn>
                <b-btn variant="primary" @click="handleSave(); handleModalClose();">
                    Сохранить и закрыть
                </b-btn>
                <b-btn variant="secondary" @click="handleModalClose">
                    Отменить
                </b-btn>
            </div>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'instructions',
    data() {
        return {
            globalArr: [],
            modalOpen: false,
            options: [],
            openCount: 0,
            selectedFilters: [],
            selectedParentOptions: [],
            lastFilter: {},
            selectedModule: {},
            selectHeaders: ['Модуль', 'Режим', 'Раздел'],
            user: {},
            fileStatus: 'INITIAL',
            addedFiles: [],
            removedFiles: []
        }
    },
    mounted() {
        this.user = this.$store.getters.user;
        this.loadGlobalArr();
        this.loadChildrenModules(0);
        this.setMargin();
    },
    methods: {
        setMargin() {
          const margin = (this.user.roles && this.user.roles.includes('gid_admin')) ? 174 : 136;
          this.$root.$emit('mainContainerMargin', margin);
        },
        async loadChildrenModules(id) {
            try {
                const { data } = await axios
                    .get('/api/guide/parent/modules',
                        { params: { parId: id } }
                    );
                if (data.length) {
                    if (id) {
                        this.selectedParentOptions.push(data);
                    } else {
                        this.selectedParentOptions = [data];
                    }
                }
            } catch (err) {
                console.log(err);
            }
        },
        handleFilterChange(index) {
            this.selectedFilters.splice(index + 1);
            this.selectedParentOptions.splice(index + 1);
            this.selectedModule = { ...this.globalArr.find(item => item.id === this.selectedFilters[index].id) } || this.selectedFilters[index];
            this.selectedModule = { ...this.selectedModule, checked: this.selectedModule.status === 'ACTIVE' };
            this.loadChildrenModules(this.selectedFilters[index].id);
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            this.addedFiles.push(file);
            this.selectedModule = { ...this.selectedModule, file: [...this.selectedModule.file, { name: file.name }] };
        },
        handleFileDelete(file) {
            if (file.hasOwnProperty('id')) {
                this.removedFiles.push(file.id);
            } else {
                this.addedFiles.splice(this.addedFiles.findIndex(f => f.name === file.name));
            }
            this.selectedModule = { ...this.selectedModule, file: this.selectedModule.file.filter(f => f.id !== file.id) };
        },
        handleEdit(item) {
            this.toggleModal();
            this.selectedFilters = [...item.parents, item];
            const promises = [];
            this.selectedFilters.forEach((parent) => {
                promises.push(axios.get('/api/guide/parent/modules',
                    { params: { parId: parent.id } }
                ).then(res => res.data));
            });
            Promise.all(promises).then(data => {
                this.selectedParentOptions.push(...data.filter(arr => arr.length));
            });
            this.selectedModule = { ...item, checked: item.status === 'ACTIVE' };
        },
        getFileExtension(fileName) {
            const words = fileName.split('.');
            return words[words.length - 1];
        },
        handleGetFile(file) {
            axios
                .get('/api/guide/file', { params: { id: file.id }, responseType: 'blob' })
                .then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', file.name);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(err => console.log(err));
        },
        handleSave() {
            const filesWithId = this.selectedModule.file.filter(file => file.hasOwnProperty('id'));
            const module = { ...this.selectedModule };
            if (this.removedFiles.length) {
                Promise
                    .all(this.removedFiles.map(file => axios.delete('/api/guide/file', { params: { id: file } })))
                    .then(() => {
                        this.globalArr = this.globalArr.map(item => item.id === module.id
                            ? { ...module }
                            : item);
                        this.removedFiles = [];
                    });
            }
            if (this.addedFiles.length) {
                Promise
                    .all(this.addedFiles.map((file) => {
                        const formData = new FormData();
                        formData.append('file', file);
                        return axios
                            .post('/api/guide/file',
                                formData,
                                {
                                    params: { moduleId: this.selectedModule.id },
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }
                            );
                    }))
                    .then((res) => {
                        this.globalArr = this.globalArr.map(item => item.id === module.id
                            ? ({ ...item, file: [...filesWithId, ...res.map(item => item.data)] })
                            : item);
                        this.addedFiles = [];
                    })
                    .catch(err => console.log(err));
            }
            const data = {
                module_id: this.selectedModule.id,
                video_link: this.selectedModule.videoLink,
                description: this.selectedModule.description,
                status: this.selectedModule.checked ? 'ACTIVE' : 'INACTIVE',
                checked: this.selectedModule.checked,
            };
            axios
                .post('/api/guide/module/add-info', {}, { params: data })
                .then(() => {
                    this.globalArr = this.globalArr.map(item => item.id === data.module_id
                        ? ({
                            ...item,
                            videoLink: data.video_link,
                            description: data.description,
                            status: data.status,
                            checked: data.checked,
                        }) : item);
                    this.changeChildrenStatus(this.globalArr.find(item => item.id === data.module_id));
                })
                .catch(err => console.log(err));
        },
        buildGlobalArr(data, parent, level) {
            const parentItem = this.globalArr.find(item => item.id === parent?.id);
            this.globalArr.push({
                id: data.id,
                name: data.name,
                nameRu: data.name,
                description: data.guide?.description,
                videoLink: data.guide?.videoLink,
                file: data.guide?.file,
                status: data.status,
                checked: data.status === 'ACTIVE',
                level,
                parent: data.children?.length > 0,
                parents: level === 1 ? [] : [...parentItem.parents, parentItem],
                parentId: parent?.id || null,
                open: false,
                visible: level === 1
            });

            data.children?.forEach(child => {
                this.buildGlobalArr(child, data, level + 1);

            })
        },
        loadGlobalArr() {
            this.globalArr = [];
            axios
                .get('/api/guide/modules')
                .then(res => {
                    res.data.children?.forEach(item => {
                        this.buildGlobalArr(item, null, 1);
                    });
                })
                .catch(err => console.log(err));
        },
        changeChildrenStatus(parent) {
            this.globalArr.forEach(item => {
                if (item.parentId === parent.id) {
                    item.status = parent.status;
                    item.checked = parent.checked;

                    if (item.parent) {
                        this.changeChildrenStatus(item, parent.status, parent.checked);
                    }
                }
            });
        },
        toggleModal() {
            if (this.modalOpen) {
                this.handleModalClose();
            } else {
                this.modalOpen = true;
            }
        },
        handleModalClose() {
            this.selectedModule = {};
            this.selectedFilters = [];
            this.selectedParentOptions = [];
            this.removedFiles = [];
            this.addedFiles = [];
            this.modalOpen = false;
            this.loadChildrenModules(0);
        },
        handleOpen(item) {
            item.open = true;
            this.openCount++;

            this.globalArr.forEach(child => {
                if (child.parentId === item.id) {
                    child.visible = true;
                }
            });
        },
        handleClose(item) {
            item.open = false;
            this.openCount--;

            this.globalArr.forEach(child => {
                if (child.parentId === item.id) {
                    child.visible = false;

                    if (child.parent && child.open) {
                        this.handleClose(child);
                    }
                }
            });
        },
        handleOpenAll() {
            this.globalArr.forEach(item => {
                if (item.parent && !item.open) {
                    this.handleOpen(item);
                }
            });
        },
        handleCloseAll() {
            this.globalArr.forEach(item => {
                if (item.parent && item.open && item.level === 1) {
                    this.handleClose(item);
                }
            });
        },
    },
}
</script>

<style>
.instructions-container {
    margin-top: 180px;
    height: calc(100vh - 180px);
}
</style>
<style lang="scss" scoped>
.filter-container {
    justify-content: flex-end;
    svg {
        margin-right: 12px;
    }
}

.tooltip {
    &__actions {
        padding: 0 5px;
    }

    &__action {
        padding: 3px 3px 3px 5px;
        text-align: left;
        cursor: pointer;

        &--edit {
            border-bottom: 1px solid #EEF1F8;
            color: #223345;
        }

        &--delete {
            color: #C0504C;
        }
    }
}

.item__instruction {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    img {
        width: 20px;
        height: 20px;
    }
}

.table-container .table {
    & .add-row {
        background-color: #F7F9FC;
        cursor: pointer;
    }

    .tr-level {
        &--1 {
            background-color: #B0E0FFB2;

            .item__title {
                text-transform: uppercase;
                font-weight: 700;
            }
        }

        &--2 {
            background-color: #B0E0FF4D;

            .item__title {
                font-weight: 500;
            }
        }

        &--3 {
            background-color: #fff;

            .item__title {
                font-weight: 400;
            }
        }
    }

    & td {
        max-width: 450px;
        vertical-align: top;

        &:last-child,
        &:first-child {
            vertical-align: middle;
        }
    }
}

.add-button {
    color: #2196F3;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.4;
}

.more-actions {
    cursor: pointer;
    width: 4px;
    margin-top: auto;
}

.modal {
    &__card {
        margin-top: 15px;
        background-color: #F7F9FC;
        border-radius: 5px;
        padding: 15px 20px 0;
        border: 1px solid #E2E5EB;
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 1.4;
    }

    &__toggle {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        line-height: 1.4;
        margin-bottom: 15px;
    }

    &__file {
        display: flex;
        gap: 20px;
        align-items: center;
        font-size: 14px;
        color: #6087A0;
    }

    &__upload {
        padding: 0 20px;
        display: flex;
        align-items: center;
        gap: 13px;

        & img {
            width: 10px;
            height: 18px;
        }

        & a {
            font-size: 14px;
            color: #6087A0;
            text-decoration: underline;
        }
    }

    &__footer {
        padding-top: 15px;
        display: flex;
        gap: 20px;

        button {
            margin-left: 0;
        }
    }
}

.card {
    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 11px 0;

        :has(> textarea) {
            align-items: flex-start;
        }

        & > *:last-child {
            width: 440px;
        }

        &:not(:last-child) {
            border-bottom: 1px solid #41618033
        }
    }
}

.row {
    &__textarea {
        height: 110px;
        resize: none;
    }

    &__input {
        border: 1px solid #BCD2E0;
        border-radius: 4px;
        font-size: 14px;
        padding: 4px 8px;
    }
}

.file {
    &__delete {
        cursor: pointer;
        color: #6087A0;
        display: flex;
        gap: 10px;
    }
}
</style>
